
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BasicHelpDialog from '@/components/BasicHelpDialog.vue';
import ItemService from '@/services/item-service';
@Component({
  components: { BasicHelpDialog }
})
export default class extends Vue {
  $refs!: {
    HelpDeskDialog: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  doesItemExists: boolean = false;
  /* Utility Functions */
  goToOrderView() {
    this.$router.push({ name: 'OrderView' });
  }
  openHelpDesk() {
    console.log('openHelpDesk');
    this.$refs.HelpDeskDialog.openDialog();
  }
  async checkIfItemExists() {
    const response = await ItemService.GetCustomerPartNumbers();
    if (response.data) {
      this.doesItemExists = !!response.data.paginatedPartNumber[0];
    }
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  created() {
    this.checkIfItemExists();
  }
  /* Emmited Functions */
}
