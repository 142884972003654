
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { InvoiceService } from '@/services/invoice-service';
import { Invoice } from '@/store/models/InvoiceModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import PaymentTerms from '@/components/PaymentTerms.vue';
import OrderSummaryBreakdown from '@/components/OrderSummaryBreakdown.vue';
import InvoiceSearchBox from './InvoiceSearchBox.vue';
import PayInvoiceDialog from './PayInvoiceDialog.vue';
import { PaymentService } from '@/services/payment-service';
import { OrderBreakdown } from '@/store/models/OrderlineModel';
import { DateHelper } from '@/helpers/DateHelper';
class MultipleInvoiceModel {
  invoice: Invoice;
  isSelected: boolean;
  isLoading: boolean;
  transactionId: string;
}
class DownloadOption {
  name: string;
  id: number;
}
@Component({
  components: {
    PaymentTerms,
    OrderSummaryBreakdown,
    InvoiceSearchBox,
    PayInvoiceDialog
  }
})
export default class MultipleInvoiceHandlerDialog extends Vue {
  $refs!: {
    paymentTerms: HTMLFormElement;
    OrderSummaryBreakdown: HTMLFormElement;
    InvoiceSearchBox: HTMLFormElement;
    PayInvoiceDialog: HTMLFormElement;
  };
  /* Properties */
  @Prop() existingInvoices!: Invoice[];
  @Prop() isLoadingMoreInvoices!: boolean;
  /* Store Actions */
  /* Watchers */
  @Watch('existingInvoices')
  onExistingInvoicesChange(val: Invoice[]) {
    if (val.length > 0) {
      this.refreshInvoicesfromInvoiceManagement();
    }
  }
  @Watch('shouldShowPayment')
  onShouldShowPaymentChange(val: boolean, oldVal: boolean) {
    if (oldVal && !val) {
      this.refreshInvoices(this.selectedInvoices.map(i => i.invoice));
    }
  }
  /* Data */
  isLoading: boolean = false;
  isDownloading: boolean = false;
  shouldShowResult: boolean = false;
  dialog: boolean = false;
  type: string = '';
  shouldShowPayment: boolean = false;
  selectedInvoice: Invoice = null;
  selectedAction: { actionName: string; buttonName: string } = {
    actionName: 'Pay Invoice',
    buttonName: 'Pay now'
  };
  actions: Array<{ actionName: string; buttonName: string }> = [
    { actionName: 'Download', buttonName: 'Download' },
    { actionName: 'Pay Invoice', buttonName: 'Pay now' }
  ];
  downloadOptions: Array<DownloadOption> = [
    { name: 'Invoices Only', id: 1 },
    { name: 'Invoices & Packlists', id: 2 },
    { name: 'Unpaid Invoices', id: 3 }
  ];
  selectedDownloadOption: DownloadOption = { name: 'Invoices Only', id: 1 };
  invoices: Array<MultipleInvoiceModel> = [];
  invoiceBreakdown: OrderBreakdown = new OrderBreakdown();
  startDate: { date: string; shouldShowPicker: boolean } = {
    date: DateHelper.ConvertDateYYYYMMDD(new Date()),
    shouldShowPicker: false
  };
  endDate: { date: string; shouldShowPicker: boolean } = {
    date: DateHelper.ConvertDateYYYYMMDD(new Date()),
    shouldShowPicker: false
  };
  shouldShowStartPicker: boolean = false;
  formatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  shouldShowPaidInvoices: boolean = false;
  paginationModel: any = {
    Take: 5,
    Skip: 0,
    Query: '',
    ShouldShowUnpaidInvoicesOnly: false,
    ShouldLoad: true,
    CurrentPage: 1
  };
  /* Computed */
  get displayedInvoices() {
    return this.selectedAction.actionName !== 'Pay Invoice' || this.shouldShowPaidInvoices
      ? this.invoices
      : this.invoices.filter(i => !i.invoice.HasPaid);
  }
  get selectedInvoices() {
    return this.invoices.filter(i => i.isSelected);
  }
  get startDateFormatted() {
    return this.formatDate(new Date(this.startDate.date));
  }
  get endDateFormatted() {
    return this.formatDate(new Date(this.endDate.date));
  }
  formatDate(date: Date) {
    var formatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    return date.toLocaleDateString('en-US', formatOptions);
  }
  /* Async Functions */
  async refreshInvoices(invoices: Invoice[]) {
    console.log(invoices);
    try {
      for (let invoice of invoices) {
        let { data } = await InvoiceService.GetInvoiceById(invoice.Id);
        let existingInvoice = this.invoices.find(i => i.invoice.Id == invoice.Id);
        existingInvoice.isSelected = false;
        existingInvoice.invoice = new Invoice(data);
      }
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
  }
  async addInvoice(invoiceId: string) {
    try {
      let existingInvoice = this.invoices.find(i => i.invoice.Id == invoiceId);
      if (!existingInvoice) {
        let { data } = await InvoiceService.GetInvoiceById(invoiceId);
        if (data) {
          existingInvoice = {
            invoice: new Invoice(data),
            isSelected: true,
            isLoading: false,
            transactionId: ''
          };
        }
      } else {
        this.invoices = this.invoices.filter(i => i.invoice.Id !== invoiceId);
        existingInvoice.isSelected = true;
      }
      this.invoices.unshift(existingInvoice);
    } catch (err) {
      console.log(err);
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
  }
  async downloadInvoicesByDateRange() {
    this.isDownloading = true;
    try {
      await InvoiceService.DownloadInvoicesByDateRange(
        new Date(this.startDate.date),
        new Date(this.endDate.date),
        this.selectedDownloadOption.id
      );
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isDownloading = false;
  }
  downloadInvoice(invoice: Invoice) {
    try {
      InvoiceService.DownloadInvoice(invoice.Id, invoice.InvoiceNumber);
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
  }
  async payInvoice(invoice: MultipleInvoiceModel) {
    try {
      let response = await PaymentService.PayInvoice(
        this.$refs.paymentTerms.defaultPaymentProfile.PaymentProfileId,
        invoice.invoice.Id
      );
      if (response.data.WasSuccessful) {
        invoice.transactionId = response.data.TransactionId;
        this.shouldShowResult = true;
        // invalidate store for invoice and reload it.
      } else {
        SetSnackBar(
          `There was a problem trying to process your card: ${response.data.Result}, please contact your bank or select a different card to try.`
        );
      }
    } catch (err) {
      SetSnackBar(
        'There was a problem trying to process your card, please contact your bank or select a different card to try.'
      );
    }
  }
  /* Utility Functions */
  openPayInvoiceDialog(invoice: Invoice) {
    this.selectedInvoice = invoice;
    this.$refs.PayInvoiceDialog.openDialog();
  }
  refreshInvoicesfromInvoiceManagement() {
    this.invoices = this.existingInvoices.map(i => {
      return {
        invoice: i,
        isSelected: false,
        isLoading: false,
        transactionId: ''
      };
    });
  }
  setInvoiceSummaryBreakdown() {
    let invoiceBreakdowns = this.selectedInvoices.map(i => i.invoice.Breakdown);
    this.invoiceBreakdown.DieTemplates = invoiceBreakdowns.reduce(
      (n, { DieTemplates }) => n + DieTemplates,
      0
    );
    this.invoiceBreakdown.TotalItems = invoiceBreakdowns.reduce(
      (n, { TotalItems }) => n + TotalItems,
      0
    );
    this.invoiceBreakdown.ExpeditedItems = invoiceBreakdowns.reduce(
      (n, { ExpeditedItems }) => n + ExpeditedItems,
      0
    );
    this.invoiceBreakdown.Materials = invoiceBreakdowns.reduce(
      (n, { Materials }) => n + Materials,
      0
    );
    this.invoiceBreakdown.Finishes = invoiceBreakdowns.reduce(
      (n, { Finishes }) => n + Finishes,
      0
    );
    this.invoiceBreakdown.ExtendedPrice = invoiceBreakdowns.reduce(
      (n, { ExtendedPrice }) => n + ExtendedPrice,
      0
    );
    this.invoiceBreakdown.SetUpFee = invoiceBreakdowns.reduce(
      (n, { SetUpFee }) => n + SetUpFee,
      0
    );
    this.invoiceBreakdown.Subtotal = invoiceBreakdowns.reduce(
      (n, { Subtotal }) => n + Subtotal,
      0
    );
  }
  canRunBatchAction() {
    switch (this.selectedAction?.actionName) {
      case 'Pay Invoice': {
        return this.canPayInvoices();
      }
      default:
        return !!this.selectedInvoices[0];
    }
  }
  canPayInvoices() {
    return (
      !!this.selectedInvoices[0] && this.selectedInvoices.every(i => !i.invoice.HasPaid)
    );
  }
  async handleSelectedActionClick() {
    switch (this.selectedAction.actionName) {
      case 'Download':
        this.isLoading = true;
        try {
          await InvoiceService.DownloadMultipleInvoices(
            this.selectedInvoices.map(i => i.invoice.Id)
          );
        } catch (err) {
          SetSnackBar(
            'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
          );
        }
        this.isLoading = false;
        return;
      case 'Pay Invoice':
        this.setInvoiceSummaryBreakdown();
        this.shouldShowPayment = true;
        return;
    }
  }
  async paySelectedInvoices() {
    this.isLoading = true;
    try {
      for (let invoice of this.selectedInvoices) {
        invoice.isLoading = true;
        const { data } = await PaymentService.PayInvoice(
          this.$refs.paymentTerms.defaultPaymentProfile.PaymentProfileId,
          invoice.invoice.Id
        );
        invoice.transactionId = data.TransactionId;
        invoice.isLoading = false;
      }
      this.shouldShowResult = true;
      this.$emit('invoicesUpdated');
      SetSnackBar('Invoices Paid Successfully');
    } catch (err) {
      for (let invoice of this.selectedInvoices) {
        invoice.isLoading = false;
      }
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isLoading = false;
  }
  openDialog(invoices: Invoice[], type: string) {
    this.type = type;
    switch (type) {
      case 'Download': {
        // do something
        break;
      }
      default: {
        this.refreshInvoicesfromInvoiceManagement();
      }
    }
    this.dialog = true;
    this.$nextTick(() => {
      const dialog = document.getElementById('multipleInvoiceHandlerDialog');
      dialog.addEventListener('scroll', this.infiniteScroll);
    });
    if (invoices.filter(i => !i.HasPaid).length < 5) this.loadMoreInvoices();
  }
  closeDialog() {
    this.resetAllData();
    this.dialog = false;
  }
  resetAllData() {
    this.isLoading = false;
    this.invoices = [];
    this.shouldShowResult = false;
    this.shouldShowPayment = false;
    this.selectedAction = {
      actionName: 'Pay Invoice',
      buttonName: 'Pay now'
    };
  }
  hasPaymentMethod() {
    let paymentProfile = this.$refs.paymentTerms?.getDefaultPaymentProfile();
    return this.$refs.paymentTerms && !!paymentProfile && !paymentProfile.IsExpired;
  }
  /* Loaders */
  async infiniteScroll() {
    // End of the document reached?
    const dialog = document.getElementById('multipleInvoiceHandlerDialog');
    if (dialog && dialog.scrollTop + dialog.clientHeight >= dialog.scrollHeight - 0.5) {
      this.paginationModel.Skip += this.paginationModel.Take;
      this.loadMoreInvoices();
    }
  }
  loadMoreInvoices() {
    this.$emit('updatePaginationModel');
  }
  /* Mounted */
  mounted() {}
  /* Created */
  /* Emmited Functions */
}
